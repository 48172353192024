import React, {useState, useEffect} from "react";
import { Helmet } from 'react-helmet'
import firebase from "gatsby-plugin-firebase";
import {SmallHero, Header, Footer} from "../fragments";
import TextInput from "../components/TextInput";
import TextArea from "../components/TextArea";
import Button from "../components/Button";
import Spinner from "../components/Spinner";

const Contact = () => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [content, setContent] = useState("");
  const [status, setStatus] = useState("editing");
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");
  const [validation, setValidation] = useState("");

  const _onSubmit = async () => {
    try {
      setError("");
      setValidation("");
      setLoading(true);
      await firebase.functions().httpsCallable('contactForm')({
        name,
        mail,
        content
      });
      setName("");
      setMail("");
      setContent("");
      setLoading(false);
      setValidation("Merci pour votre message, nous vous répondrons dans les plus brefs délais.");
      setError("");
    } catch (e) {
      setLoading(false);
      setError("Une erreur est survenue, veuillez réessayer plus tard.");
      setValidation("");
      console.error(e);
    }
  }

  return (
    <main className="overflow-hidden">
      <Helmet>
        <title>Wisoftify - Contact</title>
      </Helmet>
      <Header />
      <SmallHero title="Contact" />
      <div className="bg-gray-100 flex items-center justify-center">
        <div className="flex items-center justify-center flex-col py-5 px-8 w-full" style={{maxWidth: 900}}>
          <TextInput className="flex-1 w-full" label="Nom / Prénom:" onChange={setName} value={name}/>
          <TextInput className="flex-1 w-full" label="Mail:" onChange={setMail} value={mail}/>
          <TextArea  className="flex-1 w-full" label="Votre message:" onChange={setContent} value={content}/>
          {validation ? 
            <p className="text-center text-green-400 font-semibold pb-4">{validation}</p>
          : <></>}
          {error ?
            <p className="text-center text-red-500 font-semibold pb-4">{error}</p>
          : <></>}

          <div className="w-full flex items-center justify-center">
            <Button className="mx-auto" color="dark" onClick={_onSubmit} loading={loading}>Envoyer</Button>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default Contact;